.loginIcon {
  color: green;
}

.formError {
  color: red;
  font-size: 0.7rem;
  margin-bottom: 0;
}

.formInfo {
  color: rgb(2, 165, 2);
  font-size: 0.7rem;
  margin-bottom: 0;
}

.switchButton {
  border: none;
  background: none;
  font-size: 0.8rem;
  color: #0a58ca;
}

.eyeButton {
  cursor: pointer;
}