html {
  scroll-behavior: smooth;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
}

body {
  background-color: #f3f2ef;
  /* rgb(241, 243, 243) */
}

.center {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #0c56a5;
  animation: spin 1s linear infinite;
  z-index: 1001;
}

.spinner::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #2882dc;
  animation: spin 3s linear infinite;
}

.spinner::after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #63b9f2;
  animation: spin 1.5s linear infinite;
}

.centerMini {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinnerMini {
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #ffffff;
  animation: spin 0.5s linear infinite;
  z-index: 1001;
}

.spinnerMini::before {
  content: "";
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  bottom: 1px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #2882dc;
  animation: spin 3s linear infinite;
}

.spinnerMini::after {
  content: "";
  position: absolute;
  top: 3px;
  left: 3px;
  right: 3px;
  bottom: 3px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #63b9f2;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
