.profilePic-share {
  width: 2.5rem;
  height: 2.5rem;
}

.shareCard-input {
  background: #f7f7f7;
  font-size: 0.8rem;
}

.shareIcon-text {
  font-size: 0.8rem;
}
