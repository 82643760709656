.customMargin2 {
  margin-top: 4.5rem;
}

.customFontSideNav {
  font-size: 1.1rem;
}

.sideNavButton {
  cursor: pointer;
}

.sideNavIcon {
  display: inline-block;
  width: 27px;
  height: 27px;
}

@media screen and (max-width: 576px) {
  .customMargin2 {
    margin-top: 0;
  }
}