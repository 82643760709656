.postProfilePic {
  width: 2rem;
  height: 2rem;
}

.postCreator {
  font-size: 0.8rem;
}

.postCreated {
  font-size: 0.6rem;
}

.dropdown-menu-custom.show {
  min-width: 2rem !important;
}

.dropdown-item-custom {
  font-size: 0.8rem;
}