* {
  font-family: "Roboto", sans-serif;
}

.navBgColor {
  background: #ffffff;
}

.navbar-brand:hover {
  cursor: pointer;
}

.navbar-brand,
.navbar-brand:hover,
.navbar-brand:focus {
  color: #000000;
}

.nav-listItems>li>a,
.nav-listItems>li>a>button {
  font-size: 0.8rem;
  color: #666666;
}

.nav-listItems>li>a:hover {
  color: #191919;
}

.nav-listItems>li>a>button:hover {
  color: #fff;
  background: red;
}

.btn.show {
  border: none;
}

.dropdown-menu[data-bs-popper] {
  left: -4rem;
}

.dropdown-menu.show {
  min-width: 8em;
}

.more-icon {
  width: 2rem;
  height: 2rem;
}

.navProfilePic {
  width: 1.5rem;
  height: 1.5rem;
}

.sideNavProfilePic {
  width: 1.3rem;
  height: 1.3rem;
}

.notification {
  position: relative;
}

.notification-badge {
  position: absolute;
  top: 7px;
  left: 91%;
}