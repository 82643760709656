.scrollInView {
}

.p-content {
  font-size: 0.7rem;
}

.tags {
  font-size: 0.6rem;
}

.readMore {
  font-size: 0.6rem;
  cursor: pointer;
}
