.notification-card {
    position: absolute;
    top: 1rem;
    right: -140px;
    min-width: 20rem;
    max-height: 25rem;
}

/* .notification-wrapper {} */

.notification-item {}

.btn-sm-custom {
    font-size: 0.5rem;
    padding: 0.2rem;
    border-radius: 10px;
}

.notification-unread {
    background-color: #e7e7e7;
    border: none;
    /* border-radius: 0.5rem; */
}

.custom-divider-1 {
    border: 2px solid #f3f2ef;
}

.custom-divider-2 {
    border: 1px solid #afadad;
}

.custom-divider-1,
.custom-divider-2 {
    border-top: none;
    border-left: none;
    border-right: none;
}