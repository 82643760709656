.reset-img {
    height: 6.5%;
    width: 6.5%
}

.reset-instructions {
    list-style-type: none;
}

.report-btn {
    color: red;
}

.report-btn:hover {
    color: #fff;
    background: red;
}
.check-img {
    height: 10%;
    width: 10%
}