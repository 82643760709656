.custom-divider {
  padding-top: 0.1rem;
  margin: 0.4rem 0 0;
}

.miniPostProfilePic {
  width: 27px !important;
  height: 27px !important;
  border: 1px solid rgb(219, 219, 219);
}

.title {
  font-size: 0.7rem;
  font-weight: 700;
}