.customSideNav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: auto;
  max-width: 10rem;
}

.customOffset {
  overflow-y: auto;
}